<template>
  <div>

    <!-- modal -->
    <b-modal
      id="modal-primary-ClientCreditModal"
      :ok-only="false"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      modal-class="modal-primary-ClientCreditModal"
      :title="_title"
      size="xs"
      @ok="clickSave"
      @cancel="clickCancel"
    >
      <b-card-text>
        <validation-observer ref="formp">
          <b-form>
            <b-col cols="12">
              <b-form-group
                label="ยอดเครดิตคงเหลือ"
                label-for="a-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="a-email"
                    v-model="form.credit_sandbox"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    placeholder="ยอดเครดิตคงเหลือ"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="เพิ่มหรือลด เครดิต"
                label-for="a-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-form-input
                    id="a-amount"
                    v-model="form.amount"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    placeholder="ป้อนเครดิต"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="เครดิตรวม"
                label-for="a-credit-total"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit-total"
                  rules="required"
                >
                  <b-form-input
                    id="a-credit-total"
                    v-model="form.total"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    placeholder="ป้อนเครดิต"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-form>
        </validation-observer>
      </b-card-text>
      <pre v-show="false">
        {{_creditTotal}}
      </pre>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  props: {
    role: String,
  },
  data() {
    return {
      required,
      numeric,
      double,
      email,
      form: {
        user_id: '',
        credit_sandbox: '',
        amount: '',
        total: '',
      },
    }
  },
  computed: {
    ...mapState('dashboardDataStore', ['reloadQuery']),
    _title() {
      return `เครดิต ผู้ใช้งาน`
    },
    _creditTotal() {
      this.form.total = this.form.credit_sandbox * 1 + this.form.amount * 1
      return this.form.total
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    ...mapActions('dashboardDataStore', ['fetchData']),
    ...mapMutations('dashboardDataStore', ['QUERY_RESULTS', 'CHANGE_RELOAD_QUERY']),
    show(data) {
      this.form = {
        user_id: data.id,
        credit_sandbox: data.credit_sandbox * 1,
        amount: 0,
        total: 0,
      }
      this.$bvModal.show('modal-primary-ClientCreditModal')
    },
    async clickSave(data) {
      data.preventDefault()

      this.CHANGE_RELOAD_QUERY()
      await this.api.post('api/admin/client-update-credit', this.cp(this.form), true)

      this.$bvModal.hide('modal-primary-ClientCreditModal')
      this.fetchData()
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>
